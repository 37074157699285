import axios from "@/plugin/axios";
import {locationDevice, queryCaptcha, queryDevice, queryDeviceLogs, queryManufacturer} from "./device";


const DictApi = {
    /**
     * 查询字典列表
     * @param params
     * @returns {AxiosPromise<any>}
     */

    list: (params) => {
        let Codes = ''
        if (params?.codes){
            params.codes.forEach(item => {
                Codes += '&codes=' + item
            })
            // Codes字符串第一个替换
            Codes = Codes.replace('&codes=', '?codes=')

            return axios.get(`/general/dict/list${Codes}`)
        }else {
            return axios.get("/general/dict/list", {params})
        }
    },
}

export default DictApi